import * as React from 'react'
import * as M from 'models'
import * as A from 'actions'
import { useSelector, useDispatch } from 'react-redux'
import LayersIcon from '@mui/icons-material/Layers'
import { ISize } from 'utils/ISize'
import { VolumePanel } from './VolumePanel/VolumePanel'
import { loadSimulatedDCM, loadSimulatedSTL } from 'components/Controllers/DebugViewer'
import { bindActionCreators } from 'redux'
import { SlicedVolumeLoader } from 'utils/SlicedVolumeLoader'
import { Box, Fab } from '@mui/material'
import VolumeDown from '@mui/icons-material/BrightnessLow'
import VolumeUp from '@mui/icons-material/BrightnessHigh'
import ConfigureISOLevel from 'components/UI/VolumeTools/ConfigureISOLevel'
import { loadDefaultTextures } from 'components/Controllers/LoaderCommon'
import * as THREE from 'three'
import { ILoadOptions, IFile, Dicom, loadDicom, LoadInfo } from 'third-party/amimini'
import ViewerUI from './UI/ViewerUI'
import { parseMesh } from 'third-party/loaders'
import { useTranslation } from 'react-i18next'
import { Background } from './UI/designSystem'
import { InternalWindowMessage } from 'models'

// target radius of the STL file
const kTargetRadius = 60.0
interface SGJSProperties {
  dim: ISize
}

export async function loadDBGDCM(
  params,
  fileArray: Uint8Array[],
  noDimensionValidation,
  progressCB: (number) => void
): Promise<Dicom> {
  const files: IFile[] = fileArray.map((f, i) => {
    return {
      name: String(i),
      buffer: f,
    }
  })
  // console.log('o0:' + JSON.stringify(params))
  const options: ILoadOptions = {
    stateChange(_event: string, _comment: string) {
      //console.log('event: ' + event + ' comment:' + comment)
    },
    progressCB(pcnt: number) {
      progressCB(pcnt)
    },
    fp16Output: true,
    maxSize: params.maxSize || 200,
  }
  // console.log('o1:' + JSON.stringify(options))

  const dicom = await loadDicom('view', files, options)
  return dicom
}

interface STLObjects {
  geometry: THREE.BufferGeometry
  color: THREE.Color
  matrix: THREE.Matrix4
  hasVertexColor: boolean
  material: string | null
}

export function Viewer(props: SGJSProperties): JSX.Element {
  const loading = useSelector((state: M.AppState) => state.case.loading) as M.Loading
  const volumeStatic = useSelector((state: M.AppState) => state.case.volumeStatic) as M.VolumeStatic
  const editorConfig = useSelector((state: M.AppState) => state.case.editorConfig) as M.EditorConfig
  const latestMessage = useSelector((state: M.AppState) => state.lastInternalWindowMessage) as any
  const [handledMessage, setHandledMessage] = React.useState(new InternalWindowMessage())
  const dispatch = useDispatch()
  const width = props.dim.width
  const height = props.dim.height

  const [menuOpen, setMenuOpen] = React.useState(false)
  const { i18n } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search)

  const lang = urlParams.get('lang')
  //lang = 'hu'
  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  //console.log('editorconfig', editorConfig)

  React.useEffect(() => {
    if (latestMessage && latestMessage.req !== handledMessage.req && latestMessage.msg !== handledMessage.msg) {
      setHandledMessage(latestMessage)
      if (latestMessage.req) {
        onMessage({ data: latestMessage })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMessage?.req, latestMessage?.msg])

  let fileArray: Uint8Array[] = []
  let params: any = {}
  // let receivedCount = 0

  const progressCallBack = (pcnt: number) => {
    if (window.parent) {
      //   console.log('loading pcnt:' + pcnt)
      const errorMessage = {
        session: window['viewerSession'],
        msg: 'SGJS_LoadProgress',
        pcnt,
      }
      window.parent.postMessage(errorMessage, '*')
      // const now = Date.now()
      // console.log('progress', now - time, errorMessage)
      // time = now
    }
  }

  let actSlice = 1

  const onMessage = async (e) => {
    // console.log('xview message', e?.data)
    switch (e?.data?.msg) {
      case 'SGJS_LoadScene_Start':
        {
          progressCallBack(10)
          params = JSON.parse(new Buffer(e.data.req).toString())
        }
        break
      case 'SGJS_LoadScene':
        {
          progressCallBack(10 + (15 / params.count) * actSlice++)
          fileArray.push(new Uint8Array(e.data.slice))
        }
        break
      case 'SGJS_LoadScene_End':
        {
          progressCallBack(25)
          let loadInfo: LoadInfo = {
            errors: {},
            warnings: {},
          }
          const addError = (code: string, message?: string) => {
            loadInfo.errors[code] = { text: message, param: undefined, param2: undefined }
          }
          const addWarning = (code: string, message?: string) => {
            loadInfo.warnings[code] = { text: message, param: undefined, param2: undefined }
          }

          try {
            // const params = JSON.parse(new Buffer(e.data.req).toString())
            // for (let k = 0; k < params.count; k++) {
            //   // console.log('adding '+e.data['fileArray' + k])
            //   fileArray.push(new Uint8Array(e.data['fileArray' + k]))
            // }
            // console.log('fileArray done', fileArray)
            //console.log('loading obj ' + params.type)

            /*const urlParams = new URLSearchParams(window.location.search)
                    const simulateError = urlParams.get('simulateError')
                    const simulateWarning = urlParams.get('simulateWarning')
                    const modelColor = urlParams.get('modelColor')
                    const noDimensionValidation = urlParams.get('noDimensionValidation') || true
                    */

            // console.log('msg2:'+JSON.stringify(e?.data))

            if (params.simulateError) {
              addError('SIM_ERROR', params.simulateError)
            } else {
              if (params.type === 'stl') {
                const objs: STLObjects[] = []
                let bounding //= new THREE.Box3()
                let idx = 0
                const options = params.options
                for (const _file of fileArray) {
                  {
                    const g = parseMesh(_file.buffer, ['stl', 'ply' /*,'obj'*/], progressCallBack)
                    progressCallBack(80)
                    if (!g) {
                      addError('LOAD_ERROR')
                    } else if (g.getAttribute('position').count === 0) {
                      addError('EMPTY_MESH')
                    } else {
                      loadInfo.info = {}
                      // lets decorate it
                      loadInfo.info.vertices = g.getAttribute('position').count
                      const index = g.getIndex()
                      loadInfo.info.faces = index ? index.count : g.getAttribute('position').count / 3 //TRI indexed vs TRI_STRIPS
                      const hasVertexColor = g.getAttribute('color') ? true : false
                      loadInfo.info.hasVertexColor = hasVertexColor //TRI indexed vs TRI_STRIPS
                      if (!g.getAttribute('normal')) {
                        g.computeVertexNormals()
                      }
                      if (params.simulateWarning) {
                        addWarning('WRN_SIM', params.simulateWarning)
                      }
                      g.computeBoundingBox()
                      if (g.boundingBox) {
                        if (!bounding) {
                          bounding = g.boundingBox
                        } else {
                          ;(bounding as THREE.Box3).union(g.boundingBox)
                        }
                      }
                      const matrix = new THREE.Matrix4()

                      objs.push({
                        geometry: g,
                        color: params.material
                          ? params.material[idx].c
                          : hasVertexColor
                          ? new THREE.Color(1, 1, 1)
                          : params.modelColor
                          ? new THREE.Color(params.modelColor)
                          : new THREE.Color(1, 102 / 255, 0), //orange(ish)
                        matrix,
                        hasVertexColor,
                        material: params.material ? params.material[idx] : null,
                      })

                      //g.boundingBox
                      //g.computeBoundingSphere()
                    }
                    idx++
                  }
                }
                const sphere = new THREE.Sphere()
                bounding.getBoundingSphere(sphere)
                const scale = kTargetRadius / sphere.radius
                const tr = new THREE.Vector3().copy(sphere.center).multiplyScalar(-scale)

                for (const obj of objs) {
                  const matrix = new THREE.Matrix4().compose(
                    tr,
                    new THREE.Quaternion(),
                    new THREE.Vector3(scale, scale, scale)
                  )
                  obj.matrix = matrix
                }

                dispatch(A.Case.loadSTLForView(objs, options))
                dispatch(A.Case.loadVolumeData(null))
                progressCallBack(90)
              } else {
                // console.log('load dcm')
                // time = Date.now()
                const dicom: Dicom = await loadDBGDCM(params, fileArray, params.noDimensionValidation, progressCallBack)
                //console.log('pass1')

                if (
                  !dicom.hasErrors &&
                  dicom.min !== undefined &&
                  dicom.max !== undefined &&
                  dicom.width !== undefined &&
                  dicom.height !== undefined &&
                  dicom.imageCount !== undefined &&
                  dicom.buffer !== undefined &&
                  dicom.voxelSize !== undefined
                ) {
                  const svl = new SlicedVolumeLoader()
                  svl.loadFromBuffer(
                    1,
                    dicom.min + 32768,
                    1,
                    dicom.buffer,
                    dicom.min,
                    dicom.max,
                    dicom.width,
                    dicom.height,
                    dicom.imageCount,
                    dicom.voxelSize[0]
                  )
                  if (!svl.volume) {
                    addError('VOLUME_NOT_CREATED')
                    throw new Error('Volume not created')
                  }
                  svl.volume.data = dicom.buffer as any as Uint16Array
                  svl.volume.cubeMesh = true
                  //console.log('pass2')
                  svl.volume.initGL()
                  dispatch(
                    A.Case.loadDCMForView({
                      min: dicom.min,
                      max: dicom.max,
                      dataMin: dicom.min,
                      dataMax: dicom.max,
                      iso: dicom.windowCenter, //(min+max)/2
                      w: dicom.width,
                      h: dicom.height,
                      c: dicom.imageCount,
                      voxelSize: dicom.voxelSize[0],
                    })
                  )
                  dispatch(A.Case.loadVolumeData(svl.volume))
                } else {
                  // console.log(JSON.stringify(dicom.errors))
                }
                loadInfo = dicom
                dicom.buffer = undefined
                // console.log('load dcm done!')
              }
            }
            //console.log('loading done')
          } catch (e: any) {
            addError('EXCEPTION', e.stack)
          }
          if (!loadInfo.info) {
            loadInfo.info = {}
          }
          loadInfo.info.webgl2 = window['webglDetection']
          //console.log('info: '+JSON.stringify(loadInfo))
          if (window.parent) {
            // send back the binaries
            fileArray = []
            const msg = Object.keys(loadInfo.errors).length ? 'SGJS_LoadError' : 'SGJS_LoadOk'
            const errorMessage = {
              session: window['viewerSession'],
              //count:fileArray.length,
              msg,
              info: Uint8Array.from(Buffer.from(JSON.stringify(loadInfo))),
            }
            // const transfers: ArrayBuffer[] = []
            // for (let k = 0; k < fileArray.length; k++) {
            //   transfers.push(fileArray[k].buffer)
            //   // errorMessage['fileArray'+k] = fileArray[k].buffer
            // }
            window.parent.postMessage(errorMessage, '*')
            // console.log('onmessage done')
          }
        }
        break
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  React.useEffect(() => {
    // console.log('EFFFIKE '+loading.state)
    if (loading?.state === M.ELoadingState.NOT_LOADED) {
      dispatch(A.Case.loadStarted())
      const initFunc = async (simulateLoad: boolean, stl?: boolean) => {
        //console.log('loading starts' + stl)
        const prp = bindActionCreators({ ...A.All }, dispatch)
        await loadDefaultTextures(prp, !stl)
        if (simulateLoad) {
          if (stl) {
            loadSimulatedSTL()
          } else {
            loadSimulatedDCM()
          }
        } else {
          if (window.parent) {
            window.parent.postMessage(
              {
                session: window['viewerSession'],
                msg: 'SGJS_ReadyForInput',
              },
              '*'
            )
          }
        }
      }
      //initFunc(true,true)
      initFunc(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading?.state])

  if (loading?.state === M.ELoadingState.FULLY_LOADED && window['webglDetection']) {
    const volumePanelSize: ISize = { width, height }
    return (
      <>
        <div
          style={{
            position: 'absolute',
            width,
            height,
            left: 0,
            top: 0,
            backgroundColor: '#000000',
          }}
        >
          <VolumePanel size={volumePanelSize} />
        </div>
        {width > 1000 && editorConfig.showViewerPanel && (
          <>
            <div
              style={{
                position: 'absolute',
                left: 10,
                top: 10,
                width: 100,
                zIndex: 20,
                marginTop: '5px',
              }}
            >
              <Fab
                color='primary'
                aria-controls='FinishEdit-menu'
                aria-haspopup='true'
                size='small'
                onClick={() => {
                  setMenuOpen(true)
                }}
              >
                <LayersIcon />
              </Fab>
            </div>
            <ViewerUI open={menuOpen} openFunc={(v) => setMenuOpen(v)} />
          </>
        )}
        {volumeStatic.volume?.range && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: 'auto',
              zIndex: 20,
            }}
          >
            <Box display='flex' justifyContent='center' flexDirection='row' alignItems='center'>
              <VolumeDown color='primary' style={{ marginRight: 16 }} />
              <Box style={{ width: '50%' }}>
                <ConfigureISOLevel />
              </Box>
              <VolumeUp color='primary' style={{ marginLeft: 16 }} />
            </Box>
          </div>
        )}
      </>
    )
  } else {
    return (
      <div>
        <Background />
      </div>
    )
    // <div><h1>Loading preview...</h1></div>
  }
}
