import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as M from 'models'
import * as A from 'actions'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { makeStyles } from '@mui/styles'
import { Typography, IconButton, Fab, Box, Theme } from '@mui/material'
import clsx from 'clsx'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MailIcon from '@mui/icons-material/Mail'
import SettingsDrawer from 'components/UI/VolumeTools/SettingsDrawer'
// import SettingsIcon from '@mui/icons-material/Settings';

import { useTranslation } from 'react-i18next'
import { AboutDialog } from './About'
import FileSelector from '../FileSelector/FileSelector'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}))

function DCLDAppBar() {
  const editorState = useSelector((state: M.AppState) => state.zoom)
  const menuOpen = editorState.menu
  const settingsOpen = editorState.settings

  const [isAboutOpen, openAbout] = React.useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    // console.log('toggleDrawer', open)
    /* console.log('----------------');
            console.log('toggleDrawer'); */
    if (
      (event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) ||
      ((event as React.KeyboardEvent).keyCode > 48 && (event as React.KeyboardEvent).keyCode < 91)
    ) {
      return
    }

    // console.log('toggledrawer event', (event as React.KeyboardEvent).keyCode)
    dispatch(A.Zoom.setPanelOpen('menu', open))
    // setMenuOpen(open);
  }
  //   const sendCloseSignal = (): void => {
  //     // console.log('closing')
  //     if (window.parent) {
  //       window.parent.postMessage('SGJS_Close', '*')
  //     }
  //   }

  const [_t] = useTranslation()

  const t = (name: string, param?: any): string => {
    return _t(`sgjs.${name}`, param)
  }

  const list = (anchor = 'left') => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(false)}
      //   onKeyDown={toggleDrawer(false)}
    >
      <div>
        <IconButton color='primary' onClick={toggleDrawer(false)}>
          <Typography color='textPrimary'>{t('menu')}</Typography>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <List>
        <Divider />
        <ListItem
          button
          key='About'
          onClick={(event) => {
            event.stopPropagation()
            openAbout(true)
          }}
        >
          <ListItemIcon>
            <MailIcon color='primary' />
          </ListItemIcon>
          <ListItemText primary={t('aboutButton')} />
        </ListItem>
      </List>
    </div>
  )

  const handleSettingsDrawerOpen = (_doOpen: boolean) => {
    dispatch(A.Zoom.setPanelOpen('settings', _doOpen))
  }

  const sendInternalWindowMessage = (files: { file: File; blob: Blob; url: string }[], additionalOptions: any = {}) => {
    const jsonData = JSON.stringify({
      type: files.length >= 1 && files[0].file?.name.search('.stl') !== -1 ? M.UploadType.Stl : M.UploadType.Dicom,
      count: files.length,
      maxSize: 200,
      ...additionalOptions,
    })

    const message = files.reduce((acc, { blob }, index) => ({ ...acc, [`fileArray${index}`]: blob }), {
      msg: 'SGJS_LoadScene',
      req: Uint8Array.from(Buffer.from(jsonData)).buffer,
    })

    dispatch(A.InternalWindowMessageActions.sendMessage(message))
  }

  return menuOpen ? (
    <>
      <Drawer anchor='right' open={menuOpen} onClose={toggleDrawer(false)}>
        {list('right')}
        <FileSelector
          readBlobs
          uploadStatus='SELECTING_FILES'
          onFilesChanged={(files) => {
            sendInternalWindowMessage(files)
          }}
          readUrl={undefined}
          children={undefined}
        />
      </Drawer>
      <SettingsDrawer open={settingsOpen} openFunc={handleSettingsDrawerOpen} />
      {isAboutOpen && (
        <AboutDialog
          isOpen={isAboutOpen}
          onClose={() => {
            openAbout(false)
          }}
        />
      )}
    </>
  ) : (
    <Box style={{ position: 'absolute', right: 20, top: 20 }}>
      <Fab style={{ position: 'absolute', right: 20, top: 20, color: 'primary' }} onClick={toggleDrawer(!menuOpen)}>
        <Typography>Menu</Typography>
      </Fab>
    </Box>
  )
}

export default DCLDAppBar
