import * as THREE from 'three'
import {STLLoader} from './STLLoader.js'
import {PLYLoader} from './PLYLoader.js'
import {OBJLoader2} from './OBJLoader2.js'
export function parseMesh(buffer: ArrayBuffer, formats: string[], progressCB?: (number)=>void) : THREE.BufferGeometry {
    let rv;

    for (let k of formats) {
        try {
            switch (k) {
                case 'stl': rv =  new STLLoader().parse(buffer);
                    break
                case 'ply': rv =  (new PLYLoader() as any).parse(buffer);
                    break;
                case 'obj': rv =  (new OBJLoader2() as any).parse(buffer)
                    break
            }
            if (rv.getAttribute('position')?.count===0) {
                rv = undefined
            }
            if (rv)
                break;
        } catch (e: any) {
            console.log('exc:'+e.stack)

        }
    }
    return rv;
}